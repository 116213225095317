import {Autocomplete, Button, useTheme} from "@mui/material";
import {setPage} from "../store/ApplicationSlice";
import React, {useCallback, useMemo, useState} from "react";
import useUser from "../hooks/useUser";
import Box from "@mui/material/Box";
import DemoPrintButton from "../ic3/DemoPrintButton";
import {useDispatch} from "react-redux";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import {useAppSelector} from "../store/hooks";
import {Dashboards} from "../Pages";
import TextField from "@mui/material/TextField";
import SendIcon from '@mui/icons-material/Send';

interface Props {
    reporting: IReporting | undefined;
    reportDef: IReportDefinition | null;
}

export default function TopDashboardEditorBar(props: Props) {

    const user = useUser();
    const canEdit = (user.role === "administrator" || user.role === "editor");
    const dispatch = useDispatch();
    const currentPage = useAppSelector(state => state.application.currentPage);
    const [value, setValue] = useState<string | null>(null);
    const theme = useTheme();
    const selectedPOs = useAppSelector(state => state.application.selectedPOs);

    // Update the event in the dashboard from the autocomplete.
    const setValueDashboard = useCallback((value: string | null) => {
        setValue(value);
        props.reporting?.fireEvent("status", value == null ? null : {
            name: value, uniqueName: "[Status].[Status].[All].&[" + value + "]", caption: value
        });
    }, [props.reporting]);

    // Update the autocomplete from the event in the dashboard.
    useMemo(() => {
        props.reporting?.onEvent("status", (value) => {
            const val = Array.isArray(value) ? value[0] : value;
            setValue(val?.name ?? null);
        });
    }, [props.reporting]);

    if (!Dashboards.find(i => i.name === currentPage) && currentPage !== "CustomDashboard") {
        return null;
    }

    return <Box sx={{p: 1, borderBottom: '1px solid ' + theme.palette.divider,}} display={"flex"}
                flexDirection={"row"} gap={5} alignItems={"center"}>
        <Autocomplete
            disablePortal
            value={value}
            onChange={(event: any, newValue: string | null) => {
                setValueDashboard(newValue);
            }}
            options={["cancelled", "completed", "waiting"]}
            sx={{width: 300}}
            renderInput={(params) => <TextField {...params} label="PO Status" size={"small"}/>}
        />
        <Box display={"flex"} flexDirection={"row"} gap={1} flexGrow={1} justifyContent={"flex-end"}>
            {(selectedPOs && selectedPOs.length > 0) && <Button variant="contained" endIcon={<SendIcon/>}>
                Send {selectedPOs.length} PO{selectedPOs.length > 1 ? "s" : ""}
            </Button>}
            {canEdit && <Button variant={'outlined'} onClick={() => {
                dispatch(setPage("EditDashboard"));
            }}>Edit</Button>}
            <DemoPrintButton reporting={props.reporting} reportDef={props.reportDef}/>
        </Box>
    </Box>

}
