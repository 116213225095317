import {useEffect} from "react";
import useUser from "./useUser";

export default function useCustomHeaders() {

    const user = useUser();

    useEffect(() => {

        const onEvent = (event: MessageEvent<any>) => {

            const data = event.data;

            if (data.type === "ic3-custom-headers-request") {

                const embeddedDiv = (data.ic3callerType === "div");
                const ic3customHeaders = data.ic3customHeaders /* as specified in the URL */;

                const target = !embeddedDiv
                    ? (document.getElementById("ic3-iframe") as any)?.["contentWindow"]
                    : window
                ;

                console.info('[CustomHeaders] demo <<< ic3-custom-headers-reply(' + ic3customHeaders + ')');

                target && target.postMessage({

                    type: "ic3-custom-headers-reply",

                    data: {
                        headers: {
                            "x-ic3-user-id": user.id,
                            "x-ic3-role": user.role,
                            "x-ic3-locale": "en_US"
                        }
                    }

                }, "*");

            }
        }

        window.addEventListener("message", onEvent);

        return () => {
            window.removeEventListener("message", onEvent);
        }
    }, [user]);

}