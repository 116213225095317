import {Box, IconButton, Typography} from "@mui/material";
import * as React from 'react';
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

interface Props {
    data: any;
    title: string;
}

export default function DetailPage(props: Props) {

    return <Box display={'flex'} flexDirection={'column'} p={3}>
        <Typography variant={'h6'} pb={1}>Order details</Typography>
        <Box display={"flex"} flexDirection={"row"} gap={5} alignItems={'center'} pb={3}>
            <Typography variant={'h2'}>{props.title}</Typography>
            <IconButton color="primary">
                <ModeEditOutlineIcon/>
            </IconButton>
        </Box>
        {getDetails(props.data)}
    </Box>
}

function getDetails(data: any, title = ""): JSX.Element | null {

    if (data == null) {
        return null;
    }

    return <Box display={'flex'} flexDirection={'column'} pt={2} alignItems={'flex-start'} key={title}>
        {title && <Typography> {title}</Typography>}
        {
            Object.entries(data).map(([key, value]) => {

                if (typeof value === "object") {
                    return getDetails(value, key);
                }

                return <Box display={"flex"} flexDirection={"row"} sx={{borderBottom: '1px solid #eeeeee'}} key={key}>
                    <Typography variant={'h6'} width={'200px'}>{formatKey(key)}</Typography>
                    <Typography variant={'body1'} textAlign={'right'} width={'230px'}>{String(value)}</Typography>
                </Box>
            })
        }
    </Box>
}

function formatKey(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}