import {Box, Chip, IconButton, Paper, Skeleton, Typography} from "@mui/material";
import * as React from 'react';
import useData from "../hooks/useData";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from "lodash";
import {setOrderDetailId, setPage} from "../store/ApplicationSlice";
import {useDispatch} from "react-redux";
import useUser from "../hooks/useUser";

export default function PageActions() {

    const user = useUser();
    const data = useData(`getActions/` + user.id) as any[] | undefined;
    const dispatch = useDispatch();

    if (data == null) {
        return <Box display={'flex'} flexDirection={'column'} gap={2} p={4}>
            <Skeleton variant="rectangular" height={100}/>
            <Skeleton variant="rectangular" height={100}/>
            <Skeleton variant="rectangular" height={100}/>
        </Box>
    }

    if (!_.isArray(data)) {
        return <Box display={'flex'} flexDirection={'column'} gap={2} p={4}>
            Nothing to display
        </Box>
    }

    return <Box display={'flex'} flexDirection={'column'} gap={2} p={4} overflow={'auto'}>
        {
            data.map(i => (
                <Paper elevation={2} key={i.id}>
                    <Box display={'flex'} flexDirection={'row'} gap={6} p={3} alignItems={'flex-start'}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'caption'}>Purchase number</Typography>
                            <Typography variant={'h5'}>{i.poNumber}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'caption'}>Type</Typography>
                            <Typography variant={'h5'}>{i.type}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                            <Typography variant={'caption'}>Owner</Typography>
                            <Typography variant={'h5'}>{i.Purchaser.name}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'caption'}>Current PO status</Typography>
                            <Chip label={i.status}/>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'caption'}>Comment</Typography>
                            <Typography variant={'h5'}>{i.comment || "-"}</Typography>
                        </Box>
                        <Box flex={1}/>
                        <Box flexDirection={'row'} gap={2}>
                            <IconButton color="primary">
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton color="primary" onClick={() => {
                                dispatch(setPage("OrderDetail"));
                                dispatch(setOrderDetailId(i.id));
                            }}>
                                <ModeEditOutlineIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                </Paper>
            ))
        }
    </Box>
}