import React, {useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useDispatch} from "react-redux";
import {setIsLoggedIn, setUser} from "./store/ApplicationSlice";
import {CircularProgress} from '@mui/material';

export default function LoginScreen() {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userName = data.get('email');
        const password = data.get('password');

        if (userName) {

            setLoading(true);

            const requestOptions: RequestInit = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: userName,
                    password: password
                })
            };

            fetch('api/getPurchaserLogin', requestOptions)
                .then(response => response.json())
                .then(d => {
                    if (d['name']) {
                        dispatch(setIsLoggedIn(true));
                        dispatch(setUser({
                            id: d['id'],
                            name: d['name'],
                            email: d['email'],
                            role: d['role']
                        }));
                    } else {
                        // User not found
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }

    if (loading) {
        return <Box display={'flex'} flexDirection={'column'} pt={10} alignItems={'center'}>
            <CircularProgress/>
        </Box>
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="User"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

