import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import * as React from "react";
import {useMemo} from "react";
import useData from "../hooks/useData";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import EditIconRounded from '@mui/icons-material/EditRounded';
import DeleteIconRounded from '@mui/icons-material/DeleteRounded';
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";

interface Props {
    modelName: string;
    onRowClick?: (id: string | number) => void;
}

export default function DataShowTable(props: Props) {

    const data = useData(`get${props.modelName}`);
    const meta = useData(`get${props.modelName}Meta`);

    const columns: GridColDef<any>[] = useMemo(() => {

        if (meta == null) {
            return [];
        }

        const columns: GridColDef<any>[] = Object.entries(meta).map(([k, v]) => {
            return {
                field: k,
                flex: 1
            }
        });

        // add actions
        columns.unshift({
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIconRounded/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => props.onRowClick && props.onRowClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIconRounded/>}
                        label="Delete"
                        onClick={() => {
                        }}
                        color="inherit"
                    />,
                ];
            }
        });

        return columns;

    }, [meta, props]);

    if (!(columns && data)) {
        return null;
    }

    return <Box display={'flex'} flexDirection={'column'} gap={2}>

        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3}>
            <Typography>Filter</Typography>
            <TextField
                placeholder={"Search"}
                size={"small"}
            />
            <Button>Apply Filter</Button>
            <Button>Reset Filter</Button>
        </Box>

        <DataGrid
            rows={data}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            pageSizeOptions={[5, 10, 20, 50]}
            checkboxSelection
            disableRowSelectionOnClick
            rowHeight={38}
        />
    </Box>

}