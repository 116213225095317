import React from 'react';
import {useAppSelector} from "./store/hooks";
import LoginScreen from "./LoginScreen";
import MainPage from "./MainPage";

function App() {

    const isLoggedIn = useAppSelector(state => state.application.isLoggedIn);

    if (isLoggedIn) {
        return <MainPage/>
    }

    return <LoginScreen/>

}

export default App;
