import {useAppSelector} from "../store/hooks";
import {Box} from "@mui/material";
import PagePurchaseOrders from "./PagePurchaseOrders";
import PageDepartments from "./PageDepartments";
import PageRelationships from "./PageRelationships";
import PageActions from "./PageActions";
import PageIccubeAdmin from "./PageIccubeAdmin";
import ApplicationLoader from "../ic3/ApplicationLoader";
import {useMemo} from "react";
import {useDispatch} from "react-redux";
import {setOrderDetailId, setPage, setSelectedPOs, setSupplierDetailId, setTab} from "../store/ApplicationSlice";
import _ from "lodash";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import PageIccubeDashboardEditor from "./PageIccubeDashboardEditor";
import PageArticles from "./PageArticles";

interface Props {
    // For print button
    setReportDef: (report: IReportDefinition | null) => void;
    // For print button
    setReporting: (ic3: IReporting) => void;
    // For edit button
    reportDef: IReportDefinition | null;
}

export default function PageController(props: Props) {
    const currentPage = useAppSelector(state => state.application.currentPage);
    const currentCustomDashboard = useAppSelector(state => state.application.currentDashboard);
    const dispatch = useDispatch();

    return useMemo(() => {

        switch (currentPage) {
            case "Articles":
                return <PageArticles/>
            case "Purchase Orders":
                return <PagePurchaseOrders/>
            case "Departments":
                return <PageDepartments/>
            case "Suppliers":
                return <PageRelationships/>
            case "Your Purchase Orders":
                return <PageActions/>
            case "Admin":
                return <PageIccubeAdmin/>
            case "Overview":
                return <ApplicationLoader dashboardPath={"shared:/Overview"} setReportDef={props.setReportDef}
                                          setReporting={props.setReporting}/>
            case "Pending transactions":
                return <ApplicationLoader dashboardPath={"shared:/PO Action"} setReportDef={props.setReportDef}
                                          eventActions={[
                                              {
                                                  name: "onPoClick",
                                                  callback: value => {
                                                      const val = _.isArray(value) ? value[0] : value;
                                                      if (val != null) {
                                                          dispatch(setOrderDetailId(parseInt(val.uniqueName)));
                                                      }
                                                  }
                                              },
                                              {
                                                  name: "selSupplier",
                                                  callback: value => {
                                                      const val = _.isArray(value) ? value[0] : value;
                                                      if (val != null) {
                                                          dispatch(setPage("Suppliers"))
                                                          dispatch(setTab("Details"))
                                                          dispatch(setSupplierDetailId(parseInt(val.uniqueName)));
                                                      }
                                                  }
                                              },
                                              {
                                                  name: "selPOs",
                                                  callback: value => {
                                                      const val = _.isArray(value) ? value : [value];
                                                      dispatch(setSelectedPOs(val));
                                                  }
                                              }
                                          ]} setReporting={props.setReporting}/>
            case "EditDashboard":
                return <PageIccubeDashboardEditor reportDef={props.reportDef}/>
            case "CustomDashboard":
                return <ApplicationLoader dashboardPath={currentCustomDashboard?.path ?? ""}
                                          setReportDef={props.setReportDef}
                                          setReporting={props.setReporting}/>
            default:
                return <Box>empty</Box>
        }
    }, [currentCustomDashboard, currentPage, dispatch, props.reportDef, props.setReportDef, props.setReporting])

}