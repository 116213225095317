import {Box, Tab, Tabs} from "@mui/material";
import * as React from 'react';
import {useState} from 'react';
import {AddItemForm} from "../components/AddItemForm";
import DataShowTable from "../components/DataShowTable";

enum PageTabs {
    PURCHASES = "Purchase",
    PURCHASE_LINES = "Purchase Lines",
    PURCHASERS = "Purchaser"
}


export default function PagePurchaseOrders() {

    const [tab, setTab] = useState(Object.values(PageTabs)[0]);

    return <Box display={'flex'} flexDirection={'column'} gap={2} p={4} overflow={'auto'}>

        <Tabs value={tab} onChange={(event, newVal) => setTab(newVal)}>
            {Object.values(PageTabs).map(i =>
                <Tab key={i} value={i} label={i}/>
            )}
        </Tabs>

        <DataShowTable modelName={tab}/>

        <AddItemForm modelName={tab}/>

    </Box>
}