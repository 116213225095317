import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-app-polyfill/stable';
import CssBaseline from '@mui/material/CssBaseline';
import {Provider} from 'react-redux';
import rootStore from './store/store';
import {ThemeProvider} from "@mui/material";
import {AppTheme} from "./Theme";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <CssBaseline/>
        <ThemeProvider theme={AppTheme}>
            <Provider store={rootStore}>
                <App/>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);
