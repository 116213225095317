import {configureStore} from '@reduxjs/toolkit'
import applicationReducer from './ApplicationSlice'


const rootStore = configureStore({
    reducer: {
        application: applicationReducer
    }
})

export default rootStore

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootStore.dispatch