import {Badge} from "@mui/material";
import * as React from 'react';
import useData from "../hooks/useData";
import useUser from "../hooks/useUser";
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';

export default function PageActionsIcon() {

    const user = useUser();
    const data = useData(`getActionCount/` + user.id);

    return <Badge badgeContent={data?.['count'] ?? 0} color="secondary">
        <NotificationImportantRoundedIcon/>
    </Badge>
}