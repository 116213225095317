import {createSlice} from "@reduxjs/toolkit";
import {Pages} from "../Pages";
import {DashboardGizmo} from "../MainPage.js";
import {IEventContentItem} from "@ic3/reporting-api-embedded/dist/IReporting";

export interface PurchaseUser {
    id: number;
    name: string;
    email: string;
    role: string;
}

interface IApplicationSlice {

    currentPage: string;

    isLoggedIn: boolean;

    user?: PurchaseUser;
    supplierDetailId: number | undefined;
    orderDetailId: number | undefined;

    currentDashboard?: DashboardGizmo;

    tab?: string;

    selectedPOs?: IEventContentItem[];
}

const initialState: IApplicationSlice = {
    currentPage: Pages[0].name,
    isLoggedIn: process.env.NODE_ENV === "development",
    user: process.env.NODE_ENV === "development" ? {
        id: 0,
        name: "Aryan Hobbs",
        role: "administrator",
        email: "a.hobbs@mediprocure.com"
    } : undefined,
    supplierDetailId: undefined,
    orderDetailId: undefined,
}

const applicationSlice = createSlice({
    name: "application",
    initialState: initialState,
    reducers: {
        setPage(state, action: { payload: string }) {
            state.currentPage = action.payload;
        },
        setIsLoggedIn(state, action: { payload: boolean }) {
            state.isLoggedIn = action.payload;
        },
        setUser(state, action: { payload: PurchaseUser }) {
            state.user = action.payload;
        },
        setSupplierDetailId(state, action: { payload: number | undefined }) {
            state.supplierDetailId = action.payload;
        },
        setOrderDetailId(state, action: { payload: number | undefined }) {
            state.orderDetailId = action.payload;
        },
        setDashboard(state, action: { payload: DashboardGizmo | undefined }) {
            state.currentDashboard = action.payload;
        },
        setTab(state, action: { payload: string | undefined }) {
            state.tab = action.payload;
        },
        setSelectedPOs(state, action: { payload: (IEventContentItem | null)[] }) {
            state.selectedPOs = action.payload.filter(Boolean) as IEventContentItem[];
        },
    }
})

export const {
    setPage, setIsLoggedIn, setUser, setSupplierDetailId,
    setOrderDetailId, setDashboard,
    setTab,
    setSelectedPOs
} = applicationSlice.actions

export default applicationSlice.reducer