import {Box, Tab, Tabs} from "@mui/material";
import * as React from 'react';
import {useState} from 'react';
import {AddItemForm} from "../components/AddItemForm";
import DataShowTable from "../components/DataShowTable";

enum PageTabs {
    OVERVIEW = "Overview",
    ADD_DEPARTMENT = "Add Department",
    YOUR_DEPARTMENT = "Your Department",
    MANAGERS = "Managers"
}

export default function PageDepartments() {

    const [tab, setTab] = useState(Object.values(PageTabs)[0]);

    return <Box display={'flex'} flexDirection={'column'} gap={2} p={4} overflow={'auto'}>

        <Tabs value={tab} onChange={(event, newVal) => setTab(newVal)}>
            {Object.values(PageTabs).map(i =>
                <Tab key={i} value={i} label={i}/>
            )}
        </Tabs>

        {tab === PageTabs.ADD_DEPARTMENT && <AddItemForm modelName={"Department"}/>}

        {tab === PageTabs.OVERVIEW && <DataShowTable modelName={"Department"}/>}

    </Box>
}