import {Box} from "@mui/material";
import * as React from 'react';
import {AddItemForm} from "../components/AddItemForm";
import DataShowTable from "../components/DataShowTable";

export default function PageArticles() {

    return <Box display={'flex'} flexDirection={'column'} gap={2} p={4} overflow={'auto'}>

        <DataShowTable modelName={'Article'}/>

        <AddItemForm modelName={'Article'}/>

    </Box>
}