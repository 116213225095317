import {Box, Tab, Tabs} from "@mui/material";
import * as React from 'react';
import {AddItemForm} from "../components/AddItemForm";
import DataShowTable from "../components/DataShowTable";
import {useDispatch} from "react-redux";
import {setPage, setSupplierDetailId, setTab} from "../store/ApplicationSlice";
import PageSupplierDetail from "./PageSupplierDetail";
import {useAppSelector} from "../store/hooks";

enum PageTabs {
    OVERVIEW = "Overview",
    ADD_SUPPLIER = "Add Supplier",
    DETAILS = "Details"
}

export default function PageRelationships() {

    const tab = useAppSelector(state => {
        if (Object.values(PageTabs).includes(state.application.tab as PageTabs)) {
            return state.application.tab
        }
        return PageTabs.OVERVIEW;
    });
    const dispatch = useDispatch();

    return <Box display={'flex'} flexDirection={'column'} gap={2} p={4} overflow={'auto'}>

        <Tabs value={tab} onChange={(event, newVal) => dispatch(setTab(newVal))}>
            {Object.values(PageTabs).map(i =>
                <Tab key={i} value={i} label={i}/>
            )}
        </Tabs>

        {tab === PageTabs.OVERVIEW && <DataShowTable modelName={"Supplier"} onRowClick={(id: any) => {
            dispatch(setPage("Suppliers"));
            dispatch(setTab(PageTabs.DETAILS));
            dispatch(setSupplierDetailId(id));
        }}/>}

        {tab === PageTabs.ADD_SUPPLIER && <AddItemForm modelName={"Supplier"}/>}

        {tab === PageTabs.DETAILS && <PageSupplierDetail/>}

    </Box>
}