import * as React from 'react';
import {useMemo, useState} from 'react';
import useData from "../hooks/useData";
import {useAppSelector} from "../store/hooks";
import DetailPage from "../components/DetailPage";
import Box from "@mui/material/Box";
import {DashboardsDiv} from "../ic3/DashboardsDiv";
import {DashboardsDivContext, DashboardsDivReactContext} from "../ic3/DashboardsDivContext";
import {IReporting} from "@ic3/reporting-api-embedded";

export default function PageSupplierDetail() {

    const currentSupplier = useAppSelector(state => state.application.supplierDetailId);
    const data = useData(`getSupplierDetails/` + currentSupplier);
    const [repChart, setRepChart] = useState<IReporting>();
    const [repTable, setRepTable] = useState<IReporting>();

    const context = useMemo(() => new DashboardsDivContext({
        customHeaders: "dashboards"
    }), []);

    useMemo(() => {

        if (repChart && repTable) {

            // Forwards events from one dashboard to the other one.
            repChart.onEvent("selArticle", (eventContent) => repTable.fireEvent("selArticle", eventContent));

        }

    }, [repChart, repTable])

    const dashboardParams = useMemo(() => {
        return [{
            channelName: "supplier",
            value: {
                uniqueName: "[Supplier].[Supplier].[Supplier].&[" + data?.['id'] + "]",
                name: data?.['name'] ?? "",
                caption: data?.['name'] ?? ""
            }
        }];
    }, [data])

    return <DashboardsDivReactContext.Provider value={context}>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} flexDirection={"row"} gap={2}>
                <DetailPage data={data} title={data?.['name'] ?? ""}/>
                <DashboardsDiv className={"ic3Double-div-1"} uid={"ic3-demo-div-one"}
                               dashboardPath={"shared:/Supplier donut"} params={dashboardParams} autoResize={false}
                               setReporting={setRepChart}/>
            </Box>
            <DashboardsDiv className={"ic3Double-div-2"} uid={"ic3-demo-div-two"}
                           dashboardPath={"shared:/Supplier overview"} params={dashboardParams} autoResize={false}
                           setReporting={setRepTable}/>
        </Box>
    </DashboardsDivReactContext.Provider>
}