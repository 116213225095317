import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {IReporting} from '@ic3/reporting-api-embedded';
import {DashboardsDivReactContext} from "./DashboardsDivContext";
import {IReportParam} from "@ic3/reporting-api-embedded/dist/IReporting";
import Box from "@mui/material/Box";

interface IDashboardsDiv {

    uid: string;

    className?: string;

    autoResize?: boolean;
    dashboardPath: string;
    params?: IReportParam[];
    setReporting: (ic3: IReporting) => void;
}


export function DashboardsDiv(props: IDashboardsDiv) {

    const {
        uid, className, autoResize, dashboardPath, params,
        setReporting
    } = props;

    const context = useContext(DashboardsDivReactContext);

    const refContainer = useRef<HTMLDivElement>(null);

    const [error, setError] = useState<string>("");

    const ic3ready = useCallback((ic3: IReporting) => {
        ic3.openReport({
            path: dashboardPath,
            params: params
        });
        setReporting(ic3);
    }, [dashboardPath, params, setReporting]);

    useEffect(() => {

        if (refContainer.current) {

            const options = {
                uid,
                container: refContainer.current,
                resizingContainer: autoResize ? refContainer.current : undefined
            }

            refContainer.current && context.loadLibsAndInitialize(options)
                .then(ic3ready)
                .catch(reason => setError(reason));

        }

    }, [context, refContainer, setError, uid, autoResize, ic3ready]);

    return <Box className={className} ref={refContainer} flex={1}>{error}</Box>;
}