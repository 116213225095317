import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Alert, Box, Button, CircularProgress, Typography} from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import * as React from "react";
import {useCallback, useState} from "react";
import useData from "../hooks/useData";

interface FieldMeta {
    allowNull: boolean;
    defaultValue: unknown;
    type: "INTEGER" | "CHARACTER VARYING(255)" | "TIMESTAMP WITH TIME ZONE"
}

interface AddItemFormProps {
    modelName: string;
}

export function AddItemForm(props: AddItemFormProps) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const meta: Record<string, FieldMeta> = useData(`get${props.modelName}Meta`) ?? {};

    const onSubmit = useCallback((event: any) => {

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const object: any = {};
        formData.forEach((value, key) => {
            object[key] = value;
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        };

        setLoading(true);
        fetch(`api/add${props.modelName}`, requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data['error']) {
                    setError(data['error']);
                } else {
                    setError("");
                }
                setLoading(false)
            })
            .finally(() => {
                setLoading(false);
            });

    }, [props.modelName]);

    return <Accordion defaultExpanded={true}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
        >
            <Typography>Add a new {props.modelName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
                noValidate
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                    {
                        Object.entries(meta).map(([k, v]) => {

                            if (k === "id" || k === "createdAt" || k === "updatedAt") {
                                return null;
                            }

                            return <TextField
                                size={"small"}
                                key={k}
                                required={v.allowNull}
                                label={k}
                                id={k}
                                name={k}
                                defaultValue={v.defaultValue}
                                type={getFieldType(v.type)}
                            />
                        })
                    }
                </Box>
                <Button type="submit">Add new {props.modelName}</Button>
                {loading && <CircularProgress/>}
                {error && <Alert variant="outlined" severity="error">
                    {error}
                </Alert>}
            </Box>

        </AccordionDetails>
    </Accordion>

}

function getFieldType(type: FieldMeta['type']) {
    switch (type) {
        default:
        case "CHARACTER VARYING(255)":
            return "text"
        case "INTEGER":
            return "number"
        case "TIMESTAMP WITH TIME ZONE":
            return "date"
    }
}