import React, {useCallback, useMemo, useState} from 'react';
import {DashboardsFrame} from "./DashboardsFrame";
import {Box} from "@mui/material";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import {IEventContent} from "@ic3/reporting-api-embedded/dist/IReporting";

interface Props {
    eventActions?: { name: string, callback: (value: IEventContent | null) => void }[];
    dashboardPath: string;
    // For print button
    setReportDef: (report: IReportDefinition | null) => void;
    // For print button
    setReporting: (ic3: IReporting) => void;
}

export default function ApplicationLoader(props: Props) {

    const {eventActions, dashboardPath, setReportDef, setReporting} = props;

    // The icCube dashboards application as a IReporting instance.
    const [internalReporting, setInternalReporting] = useState<IReporting>();

    useMemo(() => {
        console.log("[ic3Div] fireEvent(selDash)", dashboardPath);
        if (internalReporting != null && dashboardPath != null) {
            internalReporting.openReport({
                path: dashboardPath,

                onDefinition: (report: IReportDefinition) => {
                    setReportDef(report);
                },

                onError: () => {
                    setReportDef(null);
                    return true /* handled */;
                }

            });
        }
    }, [dashboardPath, internalReporting, setReportDef]);

    const ic3ready = useCallback((ic3: IReporting) => {

        console.log("[ic3Div] ic3ready : ", ic3);

        const appPath = "shared:/procure-app";

        console.log("[ic3Div] ic3ready : opening " + appPath);

        ic3.openReportApp({path: appPath});

        setInternalReporting(ic3);
        setReporting(ic3);


    }, [setReporting]);

    useMemo(() => {
        internalReporting && eventActions?.forEach(i => {
            internalReporting.onEvent(i.name, i.callback);
        })
    }, [eventActions, internalReporting]);

    // URL to dashboard viewer
    const url = "/icCube/report/viewer?ic3customHeaders=dashboard";

    return (
        <Box display={'flex'} flexDirection={'column'} flex={1}>
            <DashboardsFrame containerId={"ic3dashboards"} onReady={ic3ready} url={url}/>
        </Box>
    );

}
