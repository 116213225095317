import {useAppSelector} from "../store/hooks";
import {PurchaseUser} from "../store/ApplicationSlice";

export default function useUser(): PurchaseUser {
    const user = useAppSelector(state => state.application.user);

    if (user == null) {
        throw Error("User not logged in");
    }

    return user;
}