import {useMemo, useState} from "react";

export default function useData(query: string | undefined) {

    const [data, setData] = useState();

    useMemo(() => {

        if (query == null) {

            setData(undefined);

        } else {

            const requestOptions: RequestInit = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            fetch('api/' + query, requestOptions)
                .then(response => response.json())
                .then(d => {
                    setData(d)
                });

        }

    }, [query]);

    return data;
}