import React from "react";
import ShoppingCartIconRounded from '@mui/icons-material/ShoppingCartRounded';
import LocationOnIconRounded from '@mui/icons-material/LocationOnRounded';
import GroupIconRounded from '@mui/icons-material/GroupRounded';
import DashboardIconRounded from '@mui/icons-material/DashboardRounded';
import PendingActionsIconRounded from '@mui/icons-material/PendingActionsRounded';
import PageActionsIcon from "./components/PageActionsIcon";
import CategoryIconRounded from '@mui/icons-material/CategoryRounded';

export const Pages = [
    {
        name: "Articles",
        icon: <CategoryIconRounded/>
    },
    {
        name: "Purchase Orders",
        icon: <ShoppingCartIconRounded/>
    },
    {
        name: "Departments",
        icon: <LocationOnIconRounded/>
    },
    {
        name: "Suppliers",
        icon: <GroupIconRounded/>
    },
    {
        name: "Your Purchase Orders",
        icon: <PageActionsIcon/>
    }
]

export const Dashboards = [
    {
        name: "Overview",
        icon: <DashboardIconRounded/>
    },
    {
        name: "Pending transactions",
        icon: <PendingActionsIconRounded/>
    }
];