import {createTheme} from "@mui/material/styles";
import "@fontsource/roboto/latin-300.css"
import "@fontsource/roboto/latin-400.css"
import "@fontsource/roboto/latin-700.css"

// Force loading the fonts
document.fonts.load("300 12px Roboto");
document.fonts.load("400 12px Roboto");
document.fonts.load("600 12px Roboto");

export const AppTheme = createTheme({

    typography: {
        fontFamily: "Roboto",
        fontWeightRegular: 300,
        fontWeightMedium: 400,
        fontWeightBold: 700,

        h1: {
            fontWeight: 700,
            lineHeight: 80 / 64,
            fontSize: pxToRem(40),
            ...responsiveFontSizes({sm: 52, md: 58, lg: 64}),
        },
        h2: {
            fontWeight: 700,
            lineHeight: 64 / 48,
            fontSize: pxToRem(32),
            ...responsiveFontSizes({sm: 40, md: 44, lg: 48}),
        },
        h3: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(24),
            ...responsiveFontSizes({sm: 26, md: 30, lg: 32}),
        },
        h4: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(20),
            ...responsiveFontSizes({sm: 20, md: 24, lg: 24}),
        },
        h5: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(18),
            ...responsiveFontSizes({sm: 19, md: 20, lg: 20}),
        },
        h6: {
            fontWeight: 500,
            lineHeight: 28 / 18,
            fontSize: pxToRem(17),
            ...responsiveFontSizes({sm: 18, md: 18, lg: 18}),
        },
        subtitle1: {
            fontWeight: 400,
            lineHeight: 1.5,
            fontSize: pxToRem(16),
        },
        subtitle2: {
            fontWeight: 400,
            lineHeight: 22 / 14,
            fontSize: pxToRem(14),
        },
        body1: {
            lineHeight: 1.5,
            fontSize: pxToRem(16),
        },
        body2: {
            lineHeight: 22 / 14,
            fontSize: pxToRem(14),
        },
        caption: {
            lineHeight: 1.5,
            fontSize: pxToRem(12),
        },
        overline: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: pxToRem(12),
            textTransform: 'uppercase',
        },
        button: {
            fontWeight: 500,
            lineHeight: 24 / 14,
            fontSize: pxToRem(14),
            textTransform: 'unset',
        },
    },

    palette: {
        primary: {
            main: "#c4398d"
        },
        secondary: {
            main: "#951b81"
        },
        text: {
            primary: "#000000"
        },
        error: {
            main: "#E67E70"
        },
        warning: {
            main: "#EBCC05"
        },
        success: {
            main: "#4ea204"
        },
    },

    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: '4px 4px 4px 18px',

                    '& .MuiListItemIcon-root': {
                        minWidth: '40px'
                    },

                    '& .MuiSvgIcon-root': {
                        fontSize: '20px'
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "rgba(0,0,0,0.8)"
                }
            }
        }
    }

});

export function pxToRem(value: number) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({sm, md, lg}: any) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}