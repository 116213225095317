import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import useData from "../hooks/useData";
import {useAppSelector} from "../store/hooks";
import DetailPage from "../components/DetailPage";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {setOrderDetailId} from "../store/ApplicationSlice";
import {useDispatch} from "react-redux";
import {DashboardsDiv} from "../ic3/DashboardsDiv";
import Box from "@mui/material/Box";
import {DashboardsDivContext, DashboardsDivReactContext} from "../ic3/DashboardsDivContext";
import {IReporting} from "@ic3/reporting-api-embedded";

export default function PopupOrderDetail() {

    const orderID = useAppSelector(state => state.application.orderDetailId);
    const data = useData(`getOrderDetails/` + orderID);
    const dispatch = useDispatch();

    const [repPurchaser, setRepPurchaser] = useState<IReporting>();
    const [repSupplier, setRepSupplier] = useState<IReporting>();

    const handleClose = useCallback(() => {
        dispatch(setOrderDetailId(undefined))
    }, [dispatch]);

    const context = useMemo(() => new DashboardsDivContext({
        customHeaders: "dashboards"
    }), []);

    useMemo(() => {

        if (repPurchaser && repSupplier) {

            // Forwards events from one dashboard to the other one.
            repSupplier.onEvent("selArticle", (eventContent) => repPurchaser.fireEvent("selArticle", eventContent));

        }

    }, [repPurchaser, repSupplier])

    const paramPurchaser = useMemo(() => {
        return [{
            channelName: "purchaser",
            value: {
                uniqueName: "[Purchaser].[Purchaser].[Purchaser].&[" + data?.['Purchaser']?.['id'] + "]",
                name: data?.['Purchaser']?.['name'] ?? "",
                caption: data?.['Purchaser']?.['name'] ?? ""
            }
        }];
    }, [data]);

    const paramSupplier = useMemo(() => {
        return [{
            channelName: "supplier",
            value: {
                uniqueName: "[Supplier].[Supplier].[Supplier].&[" + data?.['Supplier']?.['id'] + "]",
                name: data?.['Supplier']?.['name'] ?? "",
                caption: data?.['Supplier']?.['name'] ?? ""
            }
        }];
    }, [data]);

    return <Dialog
        open={orderID != null}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
    >
        <DialogTitle sx={{m: 0, p: 2}}>
            Order Details : {data?.['poNumber']}
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/>
        </IconButton>
        <DialogContent dividers>
            <DashboardsDivReactContext.Provider value={context}>
                <Box display={"flex"} flexDirection={"row"} gap={3}>
                    <DetailPage data={data} title={data?.['poNumber'] ?? ""}/>
                    <Box width={'300px'} display={"flex"} flexDirection={"column"} gap={3}>
                        <Box height={'300px'} overflow={'hidden'}>
                            <DashboardsDiv className={"ic3Double-div-1"} uid={"ic3-demo-div-one"}
                                           dashboardPath={"shared:/Supplier donut"} params={paramSupplier}
                                           autoResize={false}
                                           setReporting={setRepSupplier}/>

                        </Box>
                        <Box height={'300px'} overflow={'hidden'}>
                            <DashboardsDiv className={"ic3-PO-detail"} uid={"ic3-PO-detail"}
                                           dashboardPath={"shared:/PO Details"} params={paramPurchaser}
                                           autoResize={false}
                                           setReporting={setRepPurchaser}/>
                        </Box>
                    </Box>
                </Box>
            </DashboardsDivReactContext.Provider>
        </DialogContent>
    </Dialog>
}
