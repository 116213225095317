import React, {useMemo, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ListSubheader, Menu, MenuItem, useTheme} from '@mui/material';
import {Dashboards, Pages} from "./Pages";
import {setDashboard, setIsLoggedIn, setPage} from "./store/ApplicationSlice";
import {useDispatch} from "react-redux";
import PageController from "./pages/PageController";
import {useAppSelector} from "./store/hooks";
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import useCustomHeaders from "./hooks/useCustomHeaders";
import img from "./images/logo-demo-white.svg"
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import RefreshIcon from '@mui/icons-material/Refresh';
import useUser from "./hooks/useUser";
import TopDashboardEditorBar from "./components/TopDashboardEditorBar";
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import PopupOrderDetail from "./pages/PopupOrderDetail";

export default function MainPage() {

    const dispatch = useDispatch();
    const currentPage = useAppSelector(state => state.application.currentPage);
    const user = useUser();
    const currentCustomDashboard = useAppSelector(state => state.application.currentDashboard);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [reporting, setReporting] = useState<IReporting>();
    const [reportDef, setReportDef] = useState<IReportDefinition | null>(null);
    const [refresh, setRefresh] = useState<Date>(new Date());
    useCustomHeaders();
    const theme = useTheme();

    const [yourDashboards, setYourDashboards] = useState<DashboardGizmo[]>([]);

    useMemo(() => {
        console.log(refresh);
        if (user.role === "editor" || user.role === "administrator") {
            const requestOptions: RequestInit = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-ic3-user-id": String(user.id),
                    "x-ic3-role": 'administrator',
                    "x-ic3-locale": "en_US"
                },
                body: JSON.stringify({
                    type: "DASHBOARD",
                    path: "myDrive:"
                })
            };
            fetch('/icCube/api/console/admin/DocsListGizmos', requestOptions)
                .then(response => response.json())
                .then(d => {
                    setYourDashboards(d?.['payload']?.['gizmos'] ?? []);
                })
                .catch(() => {
                    console.info("DocsListGizmos not working");
                });
        }
    }, [refresh, user.role, user.id]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isAdmin = user.role === "administrator";
    const canEdit = (isAdmin || user.role === "editor");

    return (
        <Box display={"flex"} height={'100vh'}>
            <AppBar position="fixed"
                    sx={{zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
                    elevation={0}>
                <Toolbar sx={{gap: theme.spacing(4)}} variant="dense">
                    <img height={"20px"} src={img} alt={""}/>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        {currentPage}
                    </Typography>
                    <Typography variant={'body1'}>{user.email}</Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleMenu}
                        >
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => dispatch(setIsLoggedIn(false))}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: 240,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: 240, boxSizing: 'border-box'},
                }}
            >
                <Toolbar variant={'dense'}/>
                <Box sx={{overflow: 'auto'}} display={'flex'} flexDirection={'column'} height={'100%'}>
                    <List>
                        {
                            Pages.map(i => (
                                <ListItem key={i.name} disablePadding>
                                    <ListItemButton onClick={() => dispatch(setPage(i.name))}
                                                    selected={i.name === currentPage}>
                                        <ListItemIcon>
                                            {i.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={i.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                    <Divider/>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Dashboards
                            </ListSubheader>
                        }>
                        {
                            Dashboards.map(i => (
                                <ListItem key={i.name} disablePadding>
                                    <ListItemButton onClick={() => {
                                        dispatch(setPage(i.name));
                                    }} selected={i.name === currentPage}>
                                        <ListItemIcon>
                                            {i.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={i.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                        {
                           isAdmin && <ListItem key={"Admin"} disablePadding>
                                <ListItemButton onClick={() => dispatch(setPage("Admin"))}
                                                selected={"Admin" === currentPage}>
                                    <ListItemIcon>
                                        <AdminPanelSettingsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={"Admin"}/>
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>
                    {
                        canEdit && <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" sx={{
                                    display: 'flex',
                                    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                                }}>
                                    Your dashboards
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => setRefresh(new Date())}
                                    >
                                        <RefreshIcon fontSize={'small'}/>
                                    </IconButton>
                                </ListSubheader>
                            }>
                            {
                                yourDashboards.map(i => (
                                    <ListItem key={i.name} disablePadding>
                                        <ListItemButton onClick={() => {
                                            dispatch(setPage("CustomDashboard"));
                                            dispatch(setDashboard(i));
                                        }}
                                                        selected={currentPage === "CustomDashboard" && i.path === currentCustomDashboard?.path}>
                                            <ListItemIcon>
                                                <AssessmentRoundedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={i.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }
                        </List>
                    }
                    <Box flex={1}/>
                    <Divider/>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <HelpOutlineIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Info"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LanguageIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Language"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => dispatch(setIsLoggedIn(false))}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component={"main"} p={0} flex={1} width={"calc(100% - 240px);"} display={'flex'}
                 flexDirection={'column'} overflow={'hidden'}>
                <Toolbar variant={'dense'}/>
                <TopDashboardEditorBar reporting={reporting} reportDef={reportDef}/>
                <PopupOrderDetail/>
                <PageController setReportDef={setReportDef} setReporting={setReporting} reportDef={reportDef}/>
            </Box>
        </Box>
    );
}


export interface DashboardGizmo {
    name: string;
    path: string;
}