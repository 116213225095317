import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {DashboardsLoaderFrame, IReporting} from "@ic3/reporting-api-embedded";



interface IDashboardsFrame {

    /**
     * An unique ID (DOM) that is identifying the icCube dashboards instance.
     */
    containerId: string;
    onReady: (ic3: IReporting) => void;
    url: string;

}

export function DashboardsFrame(props: IDashboardsFrame) {

    const {containerId, onReady, url} = props;

    useEffect(() => {

        DashboardsLoaderFrame({containerId, onReady, url, frameId: "ic3-iframe"});

    }, [containerId, onReady, url]);

    return <Box id={containerId} height="100%"/>;
}